// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: rgb(14,78,164);
$warning:  rgb(255,152,0);
$success: rgb(36,168,93);
$light: #e9ecef;
$enable-shadows: true;
$breadcrumb-divider: quote("»");
$body-bg: rgb(240,243,246);
$card-cap-bg: none;
$card-border-color: rgb(224, 226, 229);
$input-bg: rgb(255,255,255);
$card-cap-padding-y: 1rem;
$form-floating-label-opacity:     1;
$table-active-bg:      rgba(#0dcaf0, .2);
$enable-negative-margins: true;

// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/variables";

$form-floating-height: $input-height;
$form-floating-padding-y: $input-padding-y;
$form-floating-input-padding-t:   $input-padding-y;
$form-floating-input-padding-b:   $input-padding-y;
$form-floating-label-transform:   scale(.90) translateY(-.40rem) translateX(.7rem);


// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// Custom Components
@import "custom-components/tabs/index";

// Components overrides and extensions
@import "components/components";

@import "overrides";

.form-floating{
  label{
    width: auto;
    color: $gray-600;
  }
  .form-control:not(:placeholder-shown) + label, .form-control:focus + label  {
    padding: 0 .2rem;
    height: auto;
    background-color: white;
    line-height: 1;
  }
  .form-control:focus + label{
    color: $primary;
  }
}
.shadow-inset{
  box-shadow: inset 0 10px 5px -8px rgba(0,0,0,0.11);
}

.filepond--credits{
  display: none;
}

.table-active {
  --bs-table-bg: #{$white};
  --bs-table-striped-bg: #{rgba($table-active-bg, .3)};
  --bs-table-accent-bg: #{rgba($table-active-bg, .2)};
  --bs-table-striped-color: var(--bs-table-active-color);
  --bs-table-border-color: #{rgba($table-active-bg, .5)};
  --bs-table-hover-bg: #{rgba($table-active-bg, .4)};
    border-color: var(--bs-table-border-color);
}
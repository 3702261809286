$td-btn-hover-bg: $light;
$td-active-bg: $primary;
$td-border-radius: $border-radius-sm;

@import "@eonasdan/tempus-dominus/src/scss/tempus-dominus";

.tempus-dominus-widget{

  .time-container-clock {
    padding: 1em;
    div[data-time-component="hours"], div[data-time-component="minutes"], div[data-time-component="seconds"]{
      border: 1px solid $light;
    }
    div:not(.no-highlight) {
      width: 100%;
      height: 100%;
    }
  }

  border-radius: $border-radius-sm !important;
  margin-top: 1em !important;
  .toolbar{
    div[data-action]{
      &:after{
        line-height: 1 !important;
        display:block !important;
        margin: 0 0 0 .5em !important;
        position: relative !important;
        width: auto !important;
        height: auto !important;
        content: attr(title);
      }
    }
  }
  .calendar-header{
    grid-auto-rows: 40px;
    div.previous, div.next, div.picker-switch{
      border-radius: $border-radius-sm !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      &:hover{
        background-color: $td-btn-hover-bg;
      }
    }
  }
  .day.today{
    border: 1px solid $primary;
    &:before{
      content: none !important;
    }
  }
}

.tabs__list {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;

  .tabs__item {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .tabs__link {
    position: relative;
    display: block;
    cursor: pointer;
    padding: $input-btn-padding-y $input-btn-padding-x;
    text-decoration: none;

    &.tabs__link--disabled:not(.tabs__link--active) {
      cursor: not-allowed;
      opacity: 0.7;
    }

    &:not(.tabs__link--disabled):hover {
      background-color: $gray-200;
    }

    &.tabs__link--active {
      z-index: 10;
      background-color: $primary;
      color: $white;
    }
  }

  &.tabs__list--compact {
    .tabs__link {
      padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    }
  }

  &.tabs__list--vertical-flush{
    margin-left: map-get($spacers, 3);
    .tabs__link {
      background-color: $white;
      &:not(.tabs__link--disabled):hover{
        background-color: $primary;
        color: $white;
      }
      margin-bottom: map-get($spacers, 2);
      border-radius: $border-radius;
      box-shadow: $box-shadow-sm;
      &.tabs__link--active{
        background-color: $primary;
        color: $white;
      }
    }

    &.tabs__list--compact {
      .tabs__link {
        border-radius: $border-radius-sm;
      }
    }
  }

  //Vertical right aligned
  &.tabs__list--vertical-right {
    text-align: left;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 10px;
      background: rgb(0, 0, 0);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .tabs__link {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.tabs__list--compact {
      .tabs__link {
        border-top-right-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
      }
    }
  }

  //Vertical left aligned
  &.tabs__list--vertical-left {
    text-align: right;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 10px;
      background: rgb(0, 0, 0);
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .tabs__link {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &.tabs__list--compact {
      .tabs__link {
        border-top-left-radius: $border-radius-sm;
        border-bottom-left-radius: $border-radius-sm;
      }
    }

  }

  //Horizontal
  &.tabs__list--horizontal {
    padding-top: 0.5em;
    border-bottom: 1px solid var(--#{"bs-"}border-color-translucent);
    flex-direction: row;
    flex-wrap: wrap;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .tabs__link {
      margin-bottom: -1px;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      &:not(.tabs__link--disabled):hover {
        background-color: $primary;
        color: $white;
      }

      &.tabs__link--active {
        z-index: 10;
        background-color: $card-bg;
        color: $primary;
        border-top: $card-border-width solid var(--#{"bs-"}border-color-translucent);
        border-right: $card-border-width solid var(--#{"bs-"}border-color-translucent);
        border-left: 1px solid var(--#{"bs-"}border-color-translucent);
        font-weight: bold;
      }
    }

    &.tabs__list--compact {
      .tabs__link {
        border-top-left-radius: $border-radius-sm;
        border-top-right-radius: $border-radius-sm;
      }
    }
  }
}

//Tabs Content
.tabs__content {
  display: none;

  &.tabs__content--active {
    display: block;
  }
}


















